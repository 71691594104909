<template>
    <div class="row cham-cong">
        <div class="col-md-12 col-xl-12 align-items-center">
            <div class="row">
                <div class="col-lg-12 col-xl-12 order-lg-3 order-xl-2">
                    <div class="panel">
                        <div class="panel-container show">
                            <div class="panel-content">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="bg-violet text-white ">
                                            <th>Quý</th>
                                            <th>Khu vực</th>
                                            <th>Mã nhân sự</th>
                                            <th>Giám đốc khu vực</th>
                                            <th>Tổng doanh số khu vực</th>
                                            <th>Tốc độ tăng trưởng khu vực</th>
                                            <th>Tốc độ tăng trưởng so với công ty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="!reports.length">
                                            <td colspan="7" class="text-center">Không có thông tin</td>
                                        </tr>
                                        <tr v-else v-for="(report, index) in reports" :key="index">
                                            <td>{{ report.month }}</td>
                                            <td>{{ report.khu_vuc }}</td>
                                            <td>{{ report.user_id ?? '' }}</td>
                                            <td>{{ report.user_name ?? '' }}</td>
                                            <td>{{ report.tong_doanh_thu |formatVND }}</td>
                                            <td>{{ report.toc_do_tang_truong_khu_vuc ?? 0 }} %</td>
                                            <td>{{ report.toc_do_tang_truong_cty }} %</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_REPORT_GROWTH_SALES_GDKV} from "../../../core/services/store/finances/finances.module";

export default {
    name: "GrowthRateSalesReport",
    data() {
        return {
            reports: [],
            form: {
                selectDate: null
            },
            targets: [
                'Doanh số',
                'Lợi nhuận'
            ]
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Danh sách"},
            {title: "Tốc độ tăng trưởng doanh số khu vực", icon: 'far fa-money-bill'}
        ]);
        let lastMonth = moment().format('YYYY-MM-DD');
        this.form.selectDate = new Date(lastMonth);
        this.getData();
    },
    methods: {
        getData() {
            this.form.selectDate = moment(String(this.form.selectDate)).format('YYYY-MM');
            this.$store.dispatch(GET_REPORT_GROWTH_SALES_GDKV, {month: this.form.selectDate}).then((data) => {
                this.reports = data.data;
                console.log(this.reports, ' a');
            });
        },
        search() {
            this.form.selectDate = moment(String(this.form.selectDate)).format('YYYY-MM-DD');
            this.getData();
        }
    },
    computed: {
        takeQuy() {
            return 'Quý 1';
        }
    }
}
</script>

<style scoped>
.bg-violet {
    background-color: #472F92;
}

ul {
    list-style-type: none;
}
</style>